@import "../../../styles/variables";

.message {
  width: 100%;
  height: 200px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  margin-top: 10px;
}

.photosTitle {
  margin-top: 15px;
  margin-bottom: 10px;
}

.photosContainer {
  flex-wrap: wrap !important;
  width: 100%;
}

.photoHolder {
  height: 100px;
  width: 150px;
  min-width: 150px !important;
  border-radius: 4px;
  border: 1px solid #cccccc;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 10px;
  position: relative;

  .photo {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .removePhoto {
    position: absolute;
    top: 5px;
    right: 5px;
    background: white;
    border-radius: 50%;
    font-size: 28px;
    width: 25px;
    height: 25px;
  }
}

.infoRow {
  justify-content: space-between;
  margin-bottom: 20px;
}

.infoCTA {
  color: $color-primary;
  cursor: pointer;
}

.damageTypeHolder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.damageReportType {
  min-width: 100px;
  height: 36px;
  padding: 0 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  cursor: pointer;

  &.selected {
    border: 1px solid $color-primary;
    background: $color-primary;
    color: white
  }
}
