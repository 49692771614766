@import "../../styles/variables";

.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.form {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 10px;
  padding: 20px 40px;
  justify-content: space-between;

  .signupElement {
    width: 100%;
  }

  .submitButton {
    margin-top: 30px;
  }

  .existingUser {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}


.moreActionsButtonHolder {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  height: 35px;
  width: 100%;

  .moreActionsButton {
    width: 100%;
  }
}

.moreInfoSectionRow {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  .moreInfoLabel {
    margin-right: auto;
  }

  .languageFlag {
    width: 30px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
  }
}
