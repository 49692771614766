.component {
  padding-top: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;
}

.faqHolder {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  min-height: calc(100vh - 500px) !important;
  margin-bottom: 150px;
}

.faqEditWrapper {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  width: 1000px;
  margin-bottom: 30px !important;
}

.faqInputRow {
  margin-bottom: 20px;
}

.faqLabel {
  width: 150px;
}

.faqInput {
  width: 500px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.editingButtons {
  width: 200px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.answerImage {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search {
  width: calc(100% - 40px);
  max-width: 400px;
  margin-bottom: 20px !important;
}