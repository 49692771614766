@import "../../../styles/variables";

.title {
  font-size: 24px;
  margin: 100px 20px 0 20px;
}

.content {
  min-width: 100% !important;
}

.contactFormHolder, .companyInfoHolder {
  width: 50%;
}

.mapHolder {
  width: 100%;
  height: 300px;
  position: relative;
}

.contactInfoSection {
  width: calc(100% - 20px);
  max-width: 1300px;
  margin: 10px;

  .contactInfoHolder {
    width: calc(100% - 20px);
    height: 170px;
    margin: 10px;
    //border: 1px solid #e9e9e9;
    //border-radius: 10px;
    padding-top: 50px;

    .contactInfoIcon {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    .contactInfoType {
      font-size: 14px;
    }

    .contactInfoValue {
      font-size: 14px;
      margin-top: 20px;
      text-decoration: none;
      color: $color-secondary;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (min-width: 600px) {

  .contactInfoSection {
    .contactInfoHolder {
      width: 200px;
      height: 170px;
    }
  }
}