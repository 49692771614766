@import "../../../styles/variables";

.component {
  width: 100%;
  //max-width: 1000px;
  min-height: 100vh !important;
}

.carPictures {
  width: 100%;
  height: 400px;

  .carImageLarge {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid black;
    box-sizing: border-box;
  }

  .carImagesSmall {
    display: none;
    width: 20%;
    height: 100%;

    .carImageSmall {
      width: 100%;
      height: 50%;
      object-fit: cover;
      border: 1px solid black;
      box-sizing: border-box;
    }
  }
}

@media (min-width: 768px) {
  .carPictures {
    .carImageLarge {
      width: 60%;
    }

    .carImagesSmall {
      display: flex;

      .carImageSmall {
      }
    }
  }
}

.bookingSection {
  margin: 0 0 20px 0;
}

.info {
  width: 100%;
  max-width: 900px;
  padding: 10px;
}


.pickers {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;

  .totalPrice {
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
  }

  .chosenRange {
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    max-width: 400px;
    width: 100%;
    padding: 3px;
    justify-content: space-between;

    .time, .nativeDateInputLabel {
      cursor: pointer;
      padding: 5px 5px 5px 10px;
      border-radius: 4px;
      transition: all 0.2s;
      //width: 100%;
      font-size: 14px;
      text-align: center;

      &:hover {
        background: $color-primary;
        color: white;
      }
    }

    .nativeDateInputLabel {
      width: 100%;
    }

    .inputWrapperWeb {
      width: calc(50% - 20px);
    }

    .inputWrapperMobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(50% - 20px);
      //margin-bottom: 20px !important;
    }

    .nativeDateInput {
      //visibility: hidden;
      width: 1px;
      height: 1px;
      color: white;
    }

    .timeFromTo {
      object-fit: contain;
      width: 20px;
      margin: 10px;
    }
  }

  .overlapError {
    color: red;
    height: 25px;
    font-size: 10px;
    margin: 3px 3px 0 3px;
    max-width: 400px;
  }

}


.totalCounterLabel {
  color: #00000063;
  margin-right: 10px;
  font-size: 14px;
  font-weight: normal;
}

.totalTime {
  margin-left: 10px;
}

.totalCost {
  font-size: 24px;
  font-weight: bold;
  margin-left: auto;
}

.totalCostValue {
  min-width: 120px;
  text-align: right;
}

.bookActions {
  flex-direction: column !important;
  margin-bottom: 70px !important;

  .book {
    min-width: 200px;
    width: 300px;
    align-self: center;
    margin: 10px !important;
  }

  .bookSpinner {
    align-self: center;
    margin-bottom: 10px !important;
  }

  .suspendedMessage {
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) {
  .bookActions {
    flex-direction: row !important;
  }
}


.timeline {
  width: 100%;
  //border: 1px solid #e9e9e9;
  border-radius: 4px;
  max-width: 1000px;
  height: fit-content;
  margin: 0 0 40px 0;
}

.separator {
  border-bottom: 1px solid #00000008;
  width: 100%;
}

.sectionTitle {
  margin: 20px 0 10px 0;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 768px) {

  .timeline {
    margin: 0 0 40px 0;
    width: 100%;
  }

  .sectionTitle {
    font-size: 14px;
    font-weight: bold;
  }

  .rules, .importantNotes, .cancellation {
    max-width: 600px;
    margin: 0 0 20px 0;
  }
}

.availability {
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: 5px;
}

.timelineModifyDates {
  margin-left: auto;

  .timelineArrow {
    width: 15px;
    object-fit: contain;
    cursor: pointer;
  }

  .timelineCurrentDate {
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    text-align: center;
  }
}

.sessionEnded {
  width: 100%;
  padding: 30px;
  text-align: center;
}

.totalPriceRow {
  min-height: 35px !important;
}
