.container {
  height: fit-content;
  padding: 20px;
  width: calc(100% - 20px);
  max-width: 1000px;

  .label {
    font-size: 12px;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.6);
  }

  .value {
    font-size: 16px;
  }
}

.bookingListHolder {
  max-width: 1000px;
  width: 100%;
}

.sectionHolder {
  margin: 10px;
  max-width: 1000px;
  width: calc(100% - 20px);
}

.infoSection {
  width: 200px;
  min-width: 200px !important;
  margin-right: 20px;
  margin-bottom: 20px;
}


.licencePhoto {
  max-height: 300px;
  width: 100%;
  object-fit: contain;
}

.dropzone {
  margin: 30px;
}

.bookingList {
  width: calc(100% - 20px);
  max-width: 700px;
  margin: 10px;
}

.invoicesList {
  width: 100%;
  max-width: 1000px;
  padding: 10px;
}

.adminOfPartner {
  width: 400px;
}