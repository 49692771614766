.booking {
  margin: 10px;
  width: calc(100% - 20px);
  height: 150px;
  padding: 10px;
  cursor: pointer;

  .header {
    width: 100%;
    padding: 10px 0 20px 0;

    .image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }

    .title {
      flex-grow: 1 !important;
      margin-left: 10px;
    }

    .titleLarge {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .titleSmall {
      margin-top: 5px;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .action {
      font-size: 10px;
      margin-left: 10px;
      width: 30px;
      height: 40px;

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-bottom: 5px;
      }
    }
  }

  .footer {
    border-top: 1px solid #f1f1f1;
    padding: 10px 0;
    width: 100%;

    .dates {
      color: rgba(0, 0, 0, 0.57);
      font-size: 12px;
      margin-top: 5px;

      &>div {
        margin-bottom: 5px;
      }
    }

    .price {
      font-size: 24px;
      margin-left: auto;
    }
  }
}

@media (min-width: 768px) {
  .booking {
    margin: 10px;
    width: calc(50% - 20px);
  }
}

@media (min-width: 1000px) {

  .booking {
    margin: 10px;
    width: 350px;
  }
}

.activeBooking {
  box-shadow: 0 0 13px rgba(50, 169, 54, 0.5);
}
