.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.signinForm {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 10px;
  padding: 20px 40px;

  .signinElement {
    width: 100%;
  }

  .submitButton {
    margin-top: 30px;
    text-transform: uppercase;
  }

  .forgotPassword {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}