.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.signupForm {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 10px 10px 100px;
  padding: 20px 40px;

  .signupElement {
    width: 100%;
  }

  .submitButton {
    margin-top: 30px;
  }

  .existingUser {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.tos {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.socialLogin {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
}

@media (min-width: 500px) {
  //.socialLogin {
  //  justify-content: space-between;
  //}
}

.orDivider {
  position: relative;
  font-size: 14px;
  margin-top: 0;

  &::before, &::after {
    content: ' ';
    position: absolute;
    width: 150px;
    height: 1px;
    background: #c3c3c3;
    top: 10px;
  }

  &::before {
    right: 29px;
  }

  &::after {
    left: 29px;
  }

}
