.sectionHolder {
  margin: 10px;
  //width: 400px;
}

.status {
  margin-top: 15px;
}

.imageError {
  border-color: red;
}

.typePicker {
  width: 100px;
  height: 100px;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px;
}
