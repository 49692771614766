@import '../../../styles/variables';

.component {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
  background: white;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 80px;
  position: relative;

  &:after {
    content: '';
    width: 50px;
    height: 2px;
    position: absolute;
    top: 120%;
    left: calc(50% - 25px);
    background: $color-primary;
  }
}

.steps {
  width: 100%;
  max-width: 1300px;
  justify-content: center;
}

.step {
  //border: 1px solid #e9e9e9;
  //box-shadow: 0 0 13px rgba(0, 0, 0, 0.14);
  //border-radius: 10px;
  height: 300px;
  width: 300px;
  padding: 20px;
  margin: 10px;

  .icon {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    object-fit: contain;
  }

  .stepName {
    position: relative;
    font-size: 24px;
    margin-bottom: 50px;
    margin-top: 20px;

    &:after {
      content: '';
      width: 30px;
      height: 2px;
      position: absolute;
      top: 120%;
      left: calc(50% - 15px);
      background: $color-primary;
    }
  }

  .stepDescription {
    font-size: 16px;
    text-align: center;
  }
}

@media (min-height: 650px) {
  .component {
    padding-top: 50px;
    padding-bottom: 200px;

    .title {
      margin-bottom: 130px;
    }
  }
}
