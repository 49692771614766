.datesTitle {
  color: rgba(0, 0, 0, 0.57);
  margin-top: 30px;
}

.total {
  margin-top: 30px;
  font-size: 16px;
}

.important {
  font-weight: bold;
  margin-top: 20px;
}

.phoneNumberSection {
  margin-top: 30px;
  margin-bottom: 10px;

  .phoneNumberMessage {
    font-weight: bold;
  }

  .phoneNumberHolder {
    padding: 20px;
    background: #fdfafa;
    border-radius: 10px;
    margin-top: 10px;
  }
}