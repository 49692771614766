.component {
  min-height: 100vh;
}

.content {
  margin-top: 50px;
}

.verifiedIcon {
  width: 100px;
  height: 100px;
}

.title {
  margin: 20px 20px 0 20px;
  font-size: 20px;
}

.subtitle {
  margin: 20px;
  font-size: 16px;
}
