//.componentEmptySpace {
//  height: 500px;
//  min-height: 500px;
//}

.background {
  width: 100vw;
  height: 500px;
  position: fixed;
  z-index: -10;
  object-fit: cover;
}

.component {
  width: 100%;
  height: 500px;
  color: white;
  font-weight: 400;

  .titleWrapper {
    margin: 0 20px;
  }

  .title, .demo {
    font-size: 30px;
    //margin: 50px 20px 0px 20px;
    text-transform: uppercase;
    line-height: 1.4;
    text-align: center;
  }

  .demo {
    padding: 10px;
    color: red;
    background: black;
    border-radius: 10px;
    margin: 0 20px;
  }

  .subtitle {
    margin: 10px 20px;
    //margin: 10px 20px;
    color: white;
    font-size: 16px;
    text-align: center;
  }

  .signup, .findCar {
    //margin: 30px 20px 10px;
    margin: 20px 20px 10px;
  }
}

@media (min-height: 700px) {
  //.componentEmptySpace {
  //  height: 600px;
  //  min-height: 600px;
  //}

  .component, .background {
    height: 600px;

    .title {
      font-size: 36px;
    }

    .subtitle {
      margin-bottom: 50px !important;
      font-size: 22px;
      text-transform: uppercase;
    }
  }
}

@media (min-height: 900px) {
  //.componentEmptySpace {
  //  height: 850px;
  //  min-height: 850px;
  //}

  .component, .background {
    height: 700px;
  }
}
