@import "../../../styles/variables";

.pricing {
    width: 100%;
    max-width: 1000px;

    .price {
        background: #f9f9f9;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        text-align: center;
        width: calc(50% - 20px);
        max-width: 350px;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        height: 200px;

        &:hover {
            opacity: 0.9;
        }

        .type {
            font-size: 20px;
            position: relative;

            &:after {
                content: '';
                width: 50px;
                height: 2px;
                position: absolute;
                top: 120%;
                left: calc(50% - 25px);
                background: $color-primary;
            }
        }

        .priceValue {
            margin-top: 20px;
        }

        .priceMinute {
            font-size: 12px;
        }
    }

    .active {
        background: rgba(50, 169, 54, 0.66);
        color: white;
        cursor: default;

        &:hover {
            opacity: 1;
        }

        .type {

            &:after {
                background: white;
            }
        }
    }

    .highlights {
        width: 50%;

        .highlight {
            margin: 10px;
            font-size: 12px;
        }
    }

    .subscriptionStatus {
        text-align: center;
    }
}

.confirmSelection {
    margin-top: 20px !important;
}