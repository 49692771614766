@import "../../../styles/variables";

.sessionEndedInfo {
    width: 100%;
    max-width: 400px;
    //border: 1px solid $color-primary;
    border-radius: 10px;
    padding: 20px;
    align-self: center;
    margin-bottom: 20px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.14);

    .sessionEndedSummary {
        color: $color-primary;
        align-self: center;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .sessionEndedSummarySecondary {
        margin-top: 10px;
    }

    .summaryLabel {
        color: $color-primary;
        margin-right: 10px;
        width: 120px;
    }

    .backToBookings {
        align-self: center;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
