@import "../../../styles/variables";

.component {
  background: white; // $color-primary;
  border: 2px solid $color-primary;
  border-radius: 50%;
  min-width: 25px !important;
  min-height: 25px !important;
  margin-left: -12.5px;
  margin-top: -12.5px;

  .carName {
    display: none;
  }

  &.car {
    border-color: $color-primary;
  }

  &.scooter {
    border-color: $color-orange;
  }

  &.bicycle {
    border-color: $color-blue;
  }
}

.icon {
  width: 17px;
  height: 17px;
}

//.componentHovered, .component:hover {
//  width: fit-content;
//  background: white;
//  padding: 9px;
//  min-width: 60px !important;
//  min-height: 32px !important;
//  margin-left: -20px;
//  margin-top: -12px;
//  border-radius: 4px;
//  font-size: 12px;
//  white-space: nowrap;
//  //color: white;
//  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
//  border: unset;
//  z-index: 100;
//
//  .carName {
//    display: block;
//  }
//
//  .icon {
//    display: none;
//  }
//}