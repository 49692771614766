.component {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  background: white;
  border: 1px solid #e9e9e9;
}

.carImage {
  width: 100px !important;
  height: 80px !important;;
  border-radius: 4px;
  object-fit: cover;
  margin: 5px;
  margin-right: 10px;
}

.rightSection {
  width: calc(100% - 100px);
}

.detailsContainer {
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  background: #fbfbfb;
  padding: 5px 12px;
  font-size: 14px;
  border-radius: 4px;

  .icon {
    width: 25px !important;
    height: 25px !important;
    object-fit: contain;
    margin: 5px
  }
}

.header {
  margin-bottom: 5px;

  .carType {
    font-size: 12px;
    margin-top: 6px;
  }

  .carModel {
    font-size: 16px;
    margin-top: 6px;
  }

  .address {
    font-size: 14px;
    margin-top: 4px;
  }

  .carModelSmall {
    font-size: 12px;
    margin-top: 2px;
  }

  .info {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
    margin: 10px 10px 10px auto;
  }
}

.price {
  align-self: flex-end;
  margin: 10px;
  font-weight: bold;
}

@media (min-width: 1024px) {

  .header {
    margin-bottom: 20px;

    .carType {
      font-size: 14px;
      margin-top: 10px;
    }

    .carModel {
      font-size: 18px;
      margin-top: 8px;
    }

    .address {
      font-size: 18px;
      margin-top: 8px;
    }

    .carModelSmall {
      font-size: 14px;
      margin-top: 2px;
    }

    .info {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 20px 20px 20px auto;
    }

  }

  .carImage {
    width: 250px !important;
    height: 180px !important;
  }

  .rightSection {
    width: calc(100% - 250px);
  }

  .detailsContainer {
    width: 300px;

    &.scooter {
      width: 150px;
    }

  }
}

