body {
  margin: 0;
  font-family: 'Exo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background: rgba(238, 246, 255, 0.65);
  background: white;
  color: #5c5c5b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.cleanLink {
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
}

.component {
  min-width: 100% !important;
  min-height: 100% !important;
}

.container {
  border: 1px solid #e9e9e9;
  background: white;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}

@keyframes loaderAnimation{
  0%{
    background: #eeeeee
  }
  50%{
    background: #e7e7e7;
  }
  100%{
    background: #ececec
  }

}

.pulse
{
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loaderAnimation;
  animation-timing-function: ease-in-out;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 200px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
  rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}