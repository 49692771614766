@import "src/styles/variables";

.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.form {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 10px;
  padding: 20px 40px;
  justify-content: space-between;
}

.totalOutstandingContainer {
  margin-bottom: 30px;
  width: 100%;

  .totalOutstandingText {
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }

  .totalOutstandingAmount {
    color: $color-primary;
    font-weight: bold;
    font-size: 24px;
  }

  .totalOutstandingAmountRed {
    color: red;
  }
}

.ctaButton {
  width: 100%;
}

.invoice {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  font-size: 11px;
  padding-bottom: 10px;

  .invoiceDownloadHolder {
    width: 25px;
    height: 25px;
    margin-left: 15px;
  }

  .invoiceDownload {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .invoiceId {
    font-weight: bold;
    margin-top: 3px;
    font-size: 14px;
  }

  .invoiceStatusHolder {
    margin-left: auto;

    .invoiceStatus {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .invoiceTypeIcon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}


.couponWrapper {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(179, 182, 186, 0.6);

  .couponValue {
    margin-left: auto;
  }
}

.couponInput {
  margin-bottom: 20px !important;
}

.errorUpdateCoupon {
  text-align: center;
  color: red;
}