@import "../../styles/variables";

.container {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

h1, h2, h5, strong {
  color: $color-primary;
}

h2 {
  margin-top: 30px;
}
