@import "../../../styles/variables";

.component {
  min-width: 100% !important;
  min-height: 180px !important;
  background: $color-secondary;
}

@media (max-width: 500px) {
  .component {
    min-height: 220px !important;
  }
}

.links {
  font-size: 12px;
  color: white;
  margin-bottom: 40px;

  .link {
    text-decoration: none;
    margin: 0 20px;
    width: 150px;
    text-align: center;

    div {
      color: white;
      text-decoration: none;
    }
  }

}

@media (max-width: 500px) {
  .links {
    .link {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.copy {
  margin-bottom: 20px;
  color: white;
  font-size: 12px;
}

.socialMediaLinkHolder {
  margin-left: 20px;
  margin-right: 20px;
}

.socialMediaLink {
  color: white;
  font-size: 30px !important;
}