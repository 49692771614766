@import '../../../styles/variables';

.component {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 150px;
  background: white;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 100px;
  position: relative;
  text-align: center;

  &:after {
    content: '';
    width: 50px;
    height: 2px;
    position: absolute;
    top: 120%;
    left: calc(50% - 25px);
    background: $color-primary;
  }
}

.pricing {
  width: calc(100% - 20px);
  max-width: 1000px;

  .price {
    background: #ececec;
    padding: 30px;
    margin: 10px;
    border-radius: 10px;
    text-align: center;
    width: calc(100% - 20px);
    max-width: 350px;
    font-size: 48px;
    font-weight: bold;

    .type {
      font-size: 30px;
      position: relative;

      &:after {
        content: '';
        width: 50px;
        height: 2px;
        position: absolute;
        top: 120%;
        left: calc(50% - 25px);
        background: $color-primary;
      }
    }

    .priceValue {
      margin-top: 20px;
    }

    .priceMinute {
      font-size: 16px;
    }

    .maxPrice {
      margin-top: 30px;
      font-size: 16px;
    }
  }

  .premium {
    background: rgba(50, 169, 54, 0.66);
    color: white;

    .type {

      &:after {
        background: white;
      }
    }
  }

  .highlights {
    width: 50%;

    .highlight {
      margin: 10px;
      font-size: 12px;
    }
  }
}

.priceChangeNote {
  text-align: center;
  margin: 20px;
  max-width: 700px;
}

@media (min-width: 600px) {
  .pricing {

    .price {
      width: calc(50% - 20px);
    }
  }
}
