.container {
  display: flex;
  flex-direction: column;
  //align-items: center;
}

.welcomeMessage {
  margin: 10px 10px 20px 10px;
}

.charger {
  width: 300px;
  height: 200px;
  object-fit: contain;
}
