.component {
    width: 200px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 1px 1px 0 1px rgba(0,0,0,0.05);
    transition-property: box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    font-size: 14px;
    margin-bottom: 20px;

    &:focus,
    &:hover {
        box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
    }
}

.logo {
    width: 18px;
    height: 18px;
    margin-right: 24px;
    object-fit: contain;
}
