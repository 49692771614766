@import "src/styles/variables";

.container {
  display: flex;
  width: fit-content;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  border: 1px solid rgb(198, 197, 202);
  border-radius: 4px;
  padding: 0;
  align-self: center;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li:not(:last-child) {
    border-right: 1px solid rgb(198, 197, 202);
  }

  a {
    padding: 5px 5px;
    min-width: 40px;
    text-align: center;
    //padding: 4px;
    //border-radius: 2px;
    //border: 1px solid $color-primary;
    color: rgb(145, 145, 147);
    font-weight: bold;
  }

  a:focus {
    //color: rgb(198, 197, 202);
    border: none;
    outline: none;
  }
}

.activeClass {
  //color: #fff;
  //background: $color-primary;
  background: #efedf1;

  a {
    color: rgb(101, 101, 102);
  }
}

.break {

}