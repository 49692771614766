@import "../../../styles/variables";

.component {
  width: 100vw;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
  z-index: 10;
  background: white;
}

.logo {
  object-fit: contain;
  width: 90px;
  height: 45px;
  margin-left: 30px;
  margin-right: 30px;
}

.languageFlag {
  width: 30px;
  height: 20px;
  margin-left: 10px;
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;
}

.hamburger {
  margin-left: auto;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.menu {
  margin-left: auto;
  margin-right: 10px;
  display: none !important;
}

.drawerMenu {
  width: 200px;
  min-height: 100% !important;
  padding-left: 20px;
  padding-top: 10px;

  .menuItem {
    margin-top: 20px;
  }
}

.menuItem {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }

  &.active {
    color: $color-primary;
  }
}

.desktopLanguageChange {
  display: none !important;
}

.mobileLanguageChange {
  margin-bottom: 100px;
  margin-top: auto;
}

@media (min-width: 700px) {
  .menu {
    display: flex !important;
  }

  .hamburger {
    display: none;
  }

  .desktopLanguageChange {
    display: flex !important;
  }
}