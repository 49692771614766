.sectionHolder {
  margin: 10px;
  //width: 400px;
  width: 100%;
}

.status {
  margin-top: 15px;
}

.telemetryInfo {
  margin-top: 10px;
  width: 100%;
  min-height: 150px !important;
}

.infoElement {
  width: 100%;
}

.infoLabel {
  width: 200px;
  font-weight: bold;
}

.formFieldsHolder {
  width: 100%;
}

.carInfoInput {
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
}