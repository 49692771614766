.licenceImageHolder {
  width: calc(100% - 20px);
  max-width: 500px;
  height: 220px;
  position: relative;

  .dropzone, .licencePreview {
    max-width: calc(100% - 20px);
    height: 200px;
  }

  .dropzoneError {
    border-color: red;
  }

  .licencePhoto {
    object-fit: contain;
    max-height: 180px;
  }

  .imageUploadProgressHolder {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.removePhoto {
  margin-top: 5px !important;
}

.licenceNumber {
  max-width: 400px;
  margin-bottom: 15px !important;
}

.phoneNumber {
  margin-top: 10px;
}

.subtitle, .subtitleTop {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

.title {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 24px;
  text-align: center;
}

.form {
  margin-top: 20px;
  margin-bottom: 20px;
  width: calc(100% - 20px);
  max-width: 400px;
}

.actionButton {
  margin-bottom: 100px;
}
