@import '../../../styles/variables';

.component {
  background: #313131;
  width: 100%;
}

.text {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  order: 1;

  .short {
    color: white;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .long {
    color: white;
    font-size: 12px;
  }
}

.image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  order: 2;
}

@media (min-width: 700px) {

  .component {
    min-height: 500px !important;

    .text {
      width: 50%;
      padding-left: 80px;
      padding-right: 80px;
      margin-top: 80px;
      margin-bottom: 80px;
      order: 2;
    }

    .image {
      width: 50%;
      height: unset;
      order: 1;
    }

  }
}
