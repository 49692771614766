.list {
  width: 100%;
  overflow-x: auto;
}

.listLoading {
  width: 100%;
  height: 450px;
}

.listActionButton {
  width: 200px;
  height: fit-content;
  //margin-bottom: 10px !important;
}

.loadMoreButton {
  align-self: center;
  margin-top: 20px !important;
  width: 300px;
}

.exportingStatus {
  padding-right: 20px;

  > div {
    margin-right: 10px;
  }
}

.tableRow {
  border-bottom: 1px solid #f2f2f2;
  font-size: 12px;
  min-height: 40px !important;

  &:hover {
    background: #fafafa;
    cursor: pointer;
  }
}


.cellXS, .cellXXS, .cellSmall, .cellGrow, .cellLarge {
  height: fit-content;
  padding-left: 5px;
  padding-right: 5px;
}

.cellXS {
  width: 100px;
  min-width: 100px !important;
  text-align: left;
}

.cellXXS {
  width: 80px;
  min-width: 80px !important;
  text-align: left;
}

.cellSmall {
  width: 150px;
  min-width: 150px !important;
  text-align: left;
}

.cellLarge {
  width: 250px;
  min-width: 250px !important;
  text-align: left;
}

.cellGrow {
  margin-right: auto;
  padding-left: 10px;
  flex: 1;
}

.header {
  min-height: 50px !important;
  border-bottom: 1px solid #ededed;

  .headerCell {
    //text-align: center;
  }

}

.rowIcon {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.listActionsStrip {
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.filterInput {
  width: 200px;
  margin: 10px 10px !important;
}

.actionsBar {
  margin-bottom: 20px;
}