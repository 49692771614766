@import "src/styles/variables";

.component {
  width: 100%;
  min-height: 100vh !important;
}

.sectionTitle {
  margin: 10px 10px 0 10px;
}

.overviewHolder {
  width: 100%;

  .overview {
    margin: 10px;
    width: calc(50% - 20px);
    height: calc(50vw + 30px);
    position: relative;
    box-sizing: border-box;

    .chartValueHolder {
      max-width: calc(50vw);
    }

    .chartHolder {
      //height: 200px;
      width: 100%;
      position: relative;
    }

    .valueHolder {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .value {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .type {
      font-size: 12px;
      color: $color-primary;
    }

  }

  .co2Overview {
    width: calc(100% - 20px);
  }

}

.errorHolder {
  width: 100%;
  height: 100px;
}

@media (min-width: 900px) {
  .overviewHolder {
    .overview {
      height: 300px;
      width: 300px;

      .chartValueHolder {
        max-width: unset;
      }

      .chartHolder {
        height: 300px;
        width: 300px;
      }
    }
  }
}

.bookingsHolder {
  width: 100%;
}

@media (min-width: 1000px) {
  .bookingsHolder {
    //justify-content: space-between;
  }

  .booking {
    margin: 10px;
    width: 350px;
  }
}

.noBookings {
  margin: 30px 10px 10px 20px;
  width: 100%;

  .makeBooking {
    margin-top: 20px;
  }
}

.loadMoreHolder {
  width: 100%;
  height: fit-content;
  margin: 20px;
}

.vehicleOverviewLegend {
  width: 13px;
  height: 13px;
  object-fit: contain;
  margin-right: 2px;
}

.vehicleOverviewLegendText {
  font-size: 11px !important;
}

.timeHolder {
  order: 3;
  width: 100% !important;
}

.kmHolder {
  order: 2
}

.co2Holder {
  order: 1
}


@media (min-width: 1000px) {
  .timeHolder {
    order: 1;
    width: unset !important;
  }

  .kmHolder {
    order: 2
  }

  .co2Holder {
    order: 3
  }
}