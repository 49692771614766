.detailsContainer {
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    background: #fbfbfb;
    padding: 5px 12px;
    font-size: 16px;
    border-radius: 4px;
    max-width: 450px;
    margin-bottom: 40px;

    &.scooter {
        max-width: 200px;
    }

    .icon {
        width: 25px !important;
        height: 25px !important;
        object-fit: contain;
        margin: 5px
    }

    .detailLabel {
        font-size: 10px;
        margin-top: 5px;
    }
}
