.form {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 10px;
  padding: 20px 40px;
  justify-content: space-between;

  .signupElement {
    width: 100%;
  }

  .submitButton {
    margin-top: 30px;
  }

  .existingUser {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.licenceLabel {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  align-self: flex-start;
}

.licencePhoto {
  object-fit: contain;
  max-height: 180px;
}

.imageUploadProgressHolder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.emailSection {

}

.emailChangeInput {
  width: 250px;
  margin-top: 20px;
}