.bookingSectionTitle {
    min-height: 35px;
}

.sectionTitle {
    margin: 20px 0 10px 0;
    font-size: 14px;
    font-weight: bold;
}

.rules, .importantNotes, .cancellation {
    font-size: 14px;
    max-width: calc(100% - 20px);
    margin: 10px 0;
    padding-bottom: 20px;
    white-space: pre-wrap;
}
