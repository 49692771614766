@import '../../../styles/variables';

.titleHolder {
  background: white;
  width: 100%;
  height: 80px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  position: relative;

  &:after {
    content: '';
    width: 50px;
    height: 2px;
    position: absolute;
    top: 40px;
    left: calc(50% - 25px);
    background: $color-primary;
  }
}
