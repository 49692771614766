.header {
    padding-bottom: 10px;
    padding-top: 3px;
    width: 100%;
    justify-content: center;

    .mainTitle {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .subtitle {
        font-size: 14px;
    }

    .carImage {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        object-fit: cover;
        border-radius: 10px;
        display: none !important;
    }

    .carManual {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: auto;
        display: none !important;;
    }

    .headerAction {
        margin-left: 10px;
        cursor: pointer;
        font-size: 12px;
        display: none !important;;

        .headerActionIcon {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-bottom: 5px;
        }
    }

    .carModelType {
        display: none !important;;
    }

}

@media (min-width: 500px) {
    .header {
        padding-bottom: 20px;
        padding-top: 20px;
        justify-content: flex-start;

        .carImage, .carManual, .headerAction, .carModelType{
            display: flex !important;;
        }

        .moreActionsButton {
            display: none !important;;
        }
    }
}
