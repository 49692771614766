.component {
  height: fit-content;
  cursor: pointer;
  background: white;
  min-width: 250px !important;
  min-height: 80px !important;
  padding: 10px;
  border-radius: 4px;
  margin-left: -125px;
  margin-top: -80px;
  z-index: 100;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
}

.carImage {
  width: 70px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 5px;
}

.details {
  height: 100%;
  justify-content: space-between;

  .carModel {
    font-size: 16px;
  }

  .price {
    font-weight: bold;
  }
}

