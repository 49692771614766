.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action {
  margin: 10px !important;
  width: 100%;
}
