.form {
  width: calc(100% - 20px);
  //max-width: 400px !important;
  padding: 20px;
  height: fit-content;
  margin-bottom: 15px;
}

.formElement {
  width: 100%;
}

.submitButton {
  margin-top: 50px;
  width: fit-content;
}

.formFieldsHolder {
  width: 100%;
}


.dropzone, .licencePreview {
  width: calc(100% - 20px);
  max-width: 500px;
  margin: 30px 10px 10px;
}

.imagePreviewContainer {
  width: calc(100% - 20px);
  max-width: 800px;

  .image {
    width: 100%;
  }
}

.component {
  width: 100%;
}

.titleLarge {
  font-size: 18px;
  margin-bottom: 10px;
}

.sectionTitle {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.actionButton {
  width: 200px;
  margin: 10px !important;
}

.actionButtonShort {
  margin: 10px !important;
}