.sectionHolder {
  //width: 400px;
  width: 100%;
}

.additionalInfoTitle {
  cursor: pointer;
  margin-bottom: 10px;

  &.selected {
    font-weight: bold;
  }
}

.additionalInfoInput {
  margin-bottom: 50px !important;
  width: 100%;
}

.additionalInfoLanguages {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 50px !important;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;

  .additionalInfoLanguage {
    cursor: pointer;
    min-width: 200px !important;

    &.selected {
      font-weight: bold;
    }
  }
}