.container {
  height: fit-content;
  padding: 20px;
  width: 100%;

  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  .value {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.sectionHolder {
  margin: 10px;
  width: 100%;
  max-width: 1000px;
}

.bookingInfoSection {
  width: 200px;
  min-width: 200px !important;
  margin-right: 20px;
  margin-bottom: 20px;
}

.actionButton {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.manualActions {
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
}

.manualAction {

  padding: 20px;
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 4px;
  width: 90px;
  font-size: 12px;

  &:hover {
    background: #f3f3f3;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  div {
    text-align: center;
  }
}