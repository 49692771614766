@import "../../../styles/variables";

$booking-row-height: 30px;
$entry-height: 13px;

.component {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: grey;
  //padding-left: 5px;
  //padding-right: 5px;
  //overflow: hidden;
}

.header {
  width: 100%;
  padding-left: 130px;
  border-bottom: 2px solid #dbdbdb;
  height: 60px;

  .headerContent {
    width: 100%
  }

  .headerTimeLabel {
    color: $color-primary;
    margin-bottom: 10px;
    font-weight: bold;
    margin-left: -8px;
  }

  .timestamps {
    width: 100%;

    div {
      margin-left: -20px;
      width: 40px;
      margin-right: calc(12.5% - 20px);
      text-align: left;
      position: relative;

      &:after {
        content: '';
        height: 200px;
        width: 1px;
        background: rgba(246, 246, 246, 0.54);
        position: absolute;
        top: 30px;
        left: 20px
      }
    }
  }
}

.dates {
  width: 100%;

  .datesLabelHolder {
    width: 30px;

    .datesLabel {
      width: 200px;
      text-align: center;
      height: 30px;
      transform: rotate(-90deg);
      color: $color-primary;
      font-weight: bold;
    }
  }

  .dateRows {
    width: calc(100% - 30px);

    .dataRow {
      width: 100%;
      border-bottom: 1px solid #eaeaea8a;
      height: 30px;
    }

    .dataRowGrey {
      background: #f5f5f5;
    }

    .datesCol {
      min-width: 100px;
      max-width: 100px;
      padding-left: 10px;
      font-weight: bold;
    }
  }

}

.bookings {
  width: calc(100% - 100px);
  position: relative;
  height: $booking-row-height;
  cursor: pointer;

  .eventCatcher {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  //&:hover {
  //  background: rgba(0, 0, 0, 0.03);
  //}

  .existingBooking, .newBooking, .existingBookingOverlap {
    height: $entry-height;
    border-radius: 4px;
    position: absolute;
  }

  .existingBooking {
    //background: repeating-linear-gradient(
    //                45deg,
    //                #606dbc,
    //                #606dbc 10px,
    //                #465298 10px,
    //                #465298 20px
    //);
    background: #ffcece;;
    z-index: 2;
    height: $entry-height;
  }

  .existingBookingOverlap {
    background: #ff5156;
    z-index: 2;
    height: $entry-height;
  }

  .newBooking {
    background: #73d477;
    z-index: 1;
  }

  .maintenanceSpecificLook {
    background: repeating-linear-gradient(
                    45deg,
                    #606dbc,
                    #606dbc 10px,
                    #465298 10px,
                    #465298 20px
    );
  }
}

.timePassed {
  position: absolute;

  background: repeating-linear-gradient(
                  45deg,
                  #606dbc,
                  #606dbc 10px,
                  #465298 10px,
                  #465298 20px
  );

  height: $entry-height;
  border-radius: 4px;
}

.hoverContent {
  width: 200px;
  margin-left: -100px;
  position: absolute;
  background: $color-primary;
  border-radius: 10px;
  padding: 15px;
  color: white;
  z-index: 20;
  bottom: 33px;
}
