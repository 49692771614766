@import "../../../styles/variables";

.content {
  min-width: 100% !important;
  background: white;
}

.signupForm {
  width: calc(100% - 20px);
  max-width: 1300px;
  margin: 10px;
  padding: 0px 20px;

  .signupFormTop {
    width: 100%;
    justify-content: space-between;
  }

  .signupElement {
    width: 100%;
    margin: 10px;
  }

  .signupSmall {
    width: 100%;
    margin: 10px;
  }

  .submitButtonHolder {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .submitButton {
    width: fit-content;
  }

  .statusText {
    margin-bottom: 30px;
  }
}

@media (min-width: 720px) {
  .signupForm {
    .signupSmall {
      width: calc(33% - 20px);
    }
  }
}
