@import '../../../styles/variables';

.component {
  width: 100%;
  background: white;
}

.text {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
    position: relative;

    &:after {
      content: '';
      width: 50px;
      height: 2px;
      position: absolute;
      top: 120%;
      left: calc(50% - 25px);
      background: $color-primary;
    }
  }

  .short {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .long {
    font-size: 12px;
  }
}

.image {
  width: 100%;
  height: 300px;

  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
}

@media (min-width: 700px) {

  .component {
    min-height: 500px !important;

    .text {
      width: 50%;
      padding-left: 80px;
      padding-right: 80px;
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .image {
      width: 50%;
      height: unset;

      img {
        width: 50%;
        height: 50%;
      }
    }

  }
}
