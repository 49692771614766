.manualAction {

  padding: 20px;
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 4px;
  width: 90px;
  font-size: 12px;

  &:hover {
    background: #f3f3f3;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  div {
    text-align: center;
  }
}

.adminStatus {
  margin-top: 10px;
}

.manualActions {
}

.statusLabel {
  margin-bottom: 30px;
}