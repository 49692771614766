@import "../../../styles/variables";

.sessionActionsContainer {
  width: 100%;
}

.unlockSection {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 200px;

  .lockIcon {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 5px;
  }
}

.unlockMessage {
  margin-top: 10px;
  font-size: 18px;
  padding: 20px;
  text-align: center;
}

.endSession {
  border: 1px solid $color-primary;
  color: $color-primary;
  max-width: 200px;
  max-height: 100px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 20px;
}

@media (min-width: 800px) {
  .endSession {
    margin-left: auto;
    margin-top: 0;
  }
}

.sessionTimer {
  border: 1px solid $color-primary;
  border-radius: 10px;
  padding: 10px 20px;
  flex-direction: column !important;

  .timerTimeLabel {
    text-align: center;
    font-size: 10px;
    color: $color-primary;
  }

  .timerTimeValue {
    font-size: 20px;
    width: 200px;
    text-align: center;
    color: $color-primary;
  }
}

.overtime {
  //background-color: #f7dad8;
  border-color: red;

  .timerTimeLabel {
    color: red;
  }

  .timerTimeValue {
    color: red;
  }
}

@media (min-width: 800px) {
  .sessionTimer {
    margin: 20px;
    padding: 20px 40px;
  }
}
