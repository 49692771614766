@import "../../../styles/variables";

.options {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  .carsFoundTitle {
    font-size: 18px;
  }
}

.listHolder, .horizontalListHolder {
  width: 55vw;
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;

  .listInner {
    padding: 10px 27px 10px 10px;
    position: absolute;
    max-height: 100%;
    top: 0;
    left: 0;
    right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
    overflow-y: scroll;

    .carPreview {
      width: 100vw;
      height: 160px;
      min-height: 160px !important;
      margin: 10px;

      &:hover, &.forceHover {
        box-shadow: 0 0 13px rgba(0, 0, 0, 0.14);
      }
    }

    .carPreviewNoMap {
      width: calc(100% - 20px);
    }
  }
}

.vehicleTypePicker {
  height: 49px;
  padding: 0 10px;
  z-index: 10;
  background: white;

  .vehicleTypeDivider {
    width: 1px;
    height: 20px;
    background: gray;
  }

  .vehicleTypeOption {
    border: 1px solid $color-primary;
    color: $color-primary;
    border-radius: 15px;
    cursor: pointer;
    height: 35px;
    padding: 0 10px;
    margin: 0 10px;

    &.car {
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    &.scooter {
      border: 1px solid $color-orange;
      color: $color-orange;
    }

    &.bicycle {
      border: 1px solid $color-blue;
      color: $color-blue;
    }

    &.selected {
      color: white;
      background: $color-primary;
    }

    &.selectedCar {
      color: white;
      background: $color-primary;
    }

    &.selectedScooter {
      color: white;
      background: $color-orange;
    }

    &.selectedBicycle {
      color: white;
      background: $color-blue;
    }
  }

  .vehicleTypeOptionName {
    text-align: center;
    font-size: 14px;
  }

  .vehicleTypeOptionSubcategoryName {
    text-align: center;
    font-size: 14px;
    display: none;
    margin-left: 10px;
  }

  .vehicleTypeIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

@media (min-width: 500px) {
  .vehicleTypePicker {
    .vehicleTypeOptionSubcategoryName {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .vehicleTypePicker {
    height: 49px;
    padding: 0 10px;

    .vehicleTypeOptionName {
      font-size: 16px;
    }

  }
}

.horizontalListHolder {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 160px;
  max-height: 160px;

  .listInner {
    overflow: auto;
    padding: 0;

    .carPreview {
      margin: 0;
      min-width: 100vw;
    }
  }

}

.mapHolder {
  width: 100vw;
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  position: relative;

  .mapVehicleTypeHolder {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.76);
  }
}

@media (min-width: 768px) {

  .listHolder {
    .listInner {
      .carPreview {
        height: 190px;
        min-height: 190px !important;
      }

      .carPreviewNoMap {
        width: calc(50% - 20px);
      }
    }
  }

  .mapHolder {
    width: 45vw;
  }
}

:global .carousel .slide {
  background: transparent !important;
}

:global .gm-fullscreen-control {
  display: none;
}