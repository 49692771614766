.component {
  padding-top: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;
}

.updatePaymentsMessage {
  margin-top: 50px;
  margin-bottom: 10px;
}

.authorizationErrorMessage {
  margin-top: 20px;
  margin-bottom: 10px;
}
